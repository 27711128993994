import { useSettings } from '@/composables';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const { getSetting } = useSettings();
export const useMapStore = defineStore('map', () => {
  const infoWindowOpen = ref(false);
  const currentInfoWinMarker = ref();
  const infoContent = ref('');
  const infoOptions = ref<any>({
    position: {
      lat: getSetting('city_centre_lat'),
      lng: getSetting('city_centre_lng'),
    },
    /* @ts-ignore */
    pixelOffset: {
      width: 0,
      height: -35,
    },
  });

  function getDirectionsUrl(lat, lng) {
    return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
  }

  return {
    infoWindowOpen,
    currentInfoWinMarker,
    infoContent,
    infoOptions,
    getDirectionsUrl,
  };
});

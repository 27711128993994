<template>
  <div v-if="user">
    <shift-details-dialog ref="sdd" />
    <availability-dialog
      ref="avd"
      :dont-check-training="dontCheckTrainingForBookings"
      :user="user"
      @availability:updated="refreshCalendarData"
    />
    <tabulated-calendar
      v-if="bankHolidaysLoaded && dataLoaded"
      :events="events"
      :special-dates="bankHolidays"
      :valid-range="validRange"
      add-indicator
      @date:click="onDateClick"
      @event:click="onEventClick"
      @dates:render="onDatesRender"
    />
  </div>
</template>

<script lang="ts" setup>
import { useDate, useSettings } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { computed, defineAsyncComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import AvailabilityDialog from './dialogs/AvailabilityDialog.vue';
import ShiftDetailsDialog from './dialogs/ShiftDetailsDialog.vue';

const props = withDefaults(defineProps<{
  user: App.Models.User;
  dontCheckTrainingForBookings?: boolean;
}>(), {
  dontCheckTrainingForBookings: () => false,
});

const TabulatedCalendar = defineAsyncComponent(() => import('@/components/TabulatedCalendar.vue'));

const { t } = useI18n();

const { handleError } = useErrorStore();

const { getSetting } = useSettings();
const {
  toUniversalDate,
  parseDateAndTime,
  planningDates,
  isSameOrBefore,
} = useDate();

const avd = ref<typeof AvailabilityDialog | null>(null);
const sdd = ref<typeof ShiftDetailsDialog | null>(null);

const { smAndUp } = useDisplay();
const shifts = ref<App.ApiResources.PublisherShiftResource[]>([]);
const events = ref<any>([]);
const bankHolidays = ref<any>([]);
const bankHolidaysLoaded = ref(false);
const eventDataStart = ref<string>();
const eventDataEnd = ref<string>();
const dataLoaded = ref(false);
const enabledWeeks = Number.parseInt(getSetting('planning_weeks'), 10);
const validRange = computed(() => {
  if (enabledWeeks > 0) {
    return planningDates();
  }
  return {
    start: null,
    end: null,
  };
});

interface CalendarEvent {
  title: string;
  icon: string;
  start: string;
  end: string;
  id: number;
  allDay: boolean;
  status: string;
  className: string;
  tags: string[];
}

function createEvent(shift, options): CalendarEvent {
  return {
    id: shift.shift_id,
    title: options?.title,
    icon: options?.icon,
    start: options?.start || parseDateAndTime(shift.date, shift.start),
    end: options?.end || parseDateAndTime(shift.date, shift.start),
    status: shift.status,
    allDay: false,
    className: options?.className,
    tags: shift?.tags,
  };
}

async function getBankHolidays(startDate, endDate): Promise<void> {
  try {
    const response = await axios.get(
      `/api/shifts/plan/getplannedbankholidays?start_date=${startDate}&end_date=${endDate}`,
    );
    bankHolidays.value = response.data;
    bankHolidaysLoaded.value = true;
  }
  catch (error) {
    handleError(error, t('errors.cannot_load_bank_holidays'));
  }
}

function shiftInPast(shift) {
  return shift.status === 'available' && isSameOrBefore(`${toUniversalDate(shift.date)} ${shift.begins}`);
}

async function updateCalendarData(startDate, endDate, force = false) {
  const start = toUniversalDate(startDate);
  const end = toUniversalDate(endDate);
  if (
    (start !== eventDataStart.value && end !== eventDataEnd.value)
    || force
  ) {
    eventDataStart.value = start;
    eventDataEnd.value = end;

    getBankHolidays(start, end);

    try {
      const response = await axios
        .get(`/api/users/${props.user.id}/shifts`, {
          params: {
            start_date: start,
            end_date: end,
          },
        });

      shifts.value = response.data.data;

      const availableEvents: any = [];
      const limitReachedEvents: any = [];
      const expiredEvents: any = [];

      events.value = [];

      shifts.value.forEach((shift) => {
        if (shift.status === 'assigned') {
          events.value.push(
            createEvent(shift, {
              title: smAndUp.value ? t('states.assigned') : '',
              icon: 'fa-check',
              className: 'bg-success',
            }),
          );
        }
        if (shift.status === 'available') {
          availableEvents[toUniversalDate(shift.date) + shift.begins] = shift;
        }
        if (shift.status === 'expired') {
          expiredEvents[toUniversalDate(shift.date) + shift.begins] = shift;
        }
        if (shift.status === 'limit_reached_day' || shift.status === 'limit_reached_week') {
          limitReachedEvents[toUniversalDate(shift.date) + shift.begins] = shift;
        }

        if (shift.status === 'cancelled') {
          events.value.push(
            createEvent(shift, {
              title: smAndUp.value ? t('states.cancelled') : '',
              icon: 'fa-times',
              className: 'bg-error',
            }),
          );
        }
      });

      Object.values(limitReachedEvents).forEach((shift) => {
        if (!shiftInPast(shift)) {
          events.value.push(
            createEvent(shift, {
              title: smAndUp.value ? t('shift.limit_reached') : '',
              editable: true,
              icon: 'fa-times',
              className: 'bg-error',
            }),
          );
        }
      });

      Object.values(expiredEvents).forEach((shift) => {
        if (!shiftInPast(shift)) {
          events.value.push(
            createEvent(shift, {
              title: smAndUp.value ? t('states.expired') : '',
              editable: true,
              icon: 'fa-times',
              className: 'bg-grey',
            }),
          );
        }
      });

      Object.values(availableEvents).forEach((shift) => {
        if (!shiftInPast(shift)) {
          events.value.push(
            createEvent(shift, {
              title: smAndUp.value ? t('states.available') : '',
              icon: 'fa-check',
              editable: true,
              className: 'bg-warning',
            }),
          );
        }
      });
    }
    catch (error) {
      handleError(error, t('calendar.cannot_load'));
    }
  }
  dataLoaded.value = true;
}

function refreshCalendarData() {
  updateCalendarData(eventDataStart.value, eventDataEnd.value, true);
}

function onEventClick(info) {
  if (info.event.extendedProps.status === 'assigned') {
    sdd.value?.show(Number(info.event.id));
  }
  else if (info.event.extendedProps.status !== 'cancelled') {
    avd.value?.updateAvailability(info.event.start);
  }
}

function onDateClick(info) {
  avd.value?.updateAvailability(info.date);
}

function onDatesRender(info) {
  if (enabledWeeks === 0) {
    updateCalendarData(info.view.activeStart, info.view.activeEnd);
  }
}

if (enabledWeeks > 0) {
  updateCalendarData(validRange.value.start, validRange.value.end);
}
</script>

<style scoped>
:deep(.fc-event) {
  cursor: pointer;
}

:deep(.fc-today) {
  background: #ffffcc !important;
}

:deep(.fc-content) {
  color: #fff;
}

:deep(.fc-day.special-date) {
  background-color: #e699ff;
}
</style>

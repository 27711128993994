<template>
  <v-btn
    color="primary"
    @click="dialog = true"
  >
    <div
      v-if="buttonText"
      class="me-2"
    >
      {{ buttonText }}
    </div>
    <v-icon icon="fas fa-crosshairs" />
    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <global-card :title="title">
        <v-text-field
          v-model="search"
          :label="t('global.search')"
          clearable
          @keyup.enter="searchLocation"
        >
          <template #append-inner>
            <v-btn
              color="primary"
              :text="t('global.search')"
              @click="searchLocation"
            />
          </template>
        </v-text-field>

        <smpw-map
          :center="mapCenter"
        >
          <smpw-map-marker
            :position="position"
            :icon="icon"
            :color="color"
            :read-only="false"
            @update:position="onDragEnd"
          />
        </smpw-map>

        <template #actions>
          <v-btn
            color="dark-grey"
            :text="t('actions.close')"
            @click="close"
          />
          <v-btn
            color="primary"
            :text="t('location.update')"
            @click="sendLocationUpdate"
          />
        </template>
      </global-card>
    </v-dialog>
  </v-btn>
</template>

<script setup lang="ts">
import SmpwMap from '@/components/globals/SmpwMap.vue';
import SmpwMapMarker from '@/components/globals/SmpwMapMarker.vue';
import { useSettings } from '@/composables';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

withDefaults(defineProps<{
  title?: string;
  buttonText?: string;
  icon: string;
  color?: string;
}>(), {
  title: () => useI18n().t('location.search'),
});

const emit = defineEmits(['update:location']);

const { t } = useI18n();

const { getSetting } = useSettings();
const dialog = ref(false);

const mapCenter = ref({
  lat: getSetting('city_centre_lat'),
  lng: getSetting('city_centre_lng'),
});

const search = ref('');

const position = ref({
  lat: getSetting('city_centre_lat'),
  lng: getSetting('city_centre_lng'),
});

function onDragEnd(e) {
  position.value.lat = e.latLng.lat();
  position.value.lng = e.latLng.lng();
}

function searchLocation() {
  /** @ts-ignore */
  const geocoder = new google.maps.Geocoder();
  geocoder.geocode({ address: search.value }, (results, status) => {
    if (status === 'OK') {
      position.value = {
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng(),
      };
      mapCenter.value = { ...position.value };
    }
  });
}

function close() {
  dialog.value = false;
}

function sendLocationUpdate() {
  emit('update:location', position.value);
  close();
}
</script>

<template>
  <v-app-bar
    color="primary-darken-1"
    :height="mobile ? '100' : undefined"
    :elevation="0"
  >
    <template
      v-if="mobile"
      #image
    >
      <v-img
        src="/storage/logo/logo_light.svg"
        contain
        class="ma-2"
      />
    </template>
    <template #prepend>
      <v-app-bar-nav-icon
        :size="smAndUp ? 'large' : 'small'"
        @click="emits('toggleNavigation')"
      />
    </template>
    <div
      class="d-flex flex-wrap ga-1 me-2"
      :style="{ maxWidth: smAndUp ? undefined : '68px' }"
    >
      <v-btn
        v-if="canAny(['manage-shifts', 'view-all-shifts'])"
        to="/admin/shifts/schedule"
        data-testid="nav-shift-management"
        icon="fas fa-fw fa-calendar-alt"
        :size="smAndUp ? 'large' : 'x-small'"
      />
      <v-btn
        v-if="can('manage-users')"
        to="/admin/users"
        icon="fas fa-fw fa-user-friends"
        :size="smAndUp ? 'large' : 'x-small'"
      />
      <v-btn
        v-if="authUser.is_admin"
        to="/admin"
        icon="fas fa-fw fa-key"
        :size="smAndUp ? 'large' : 'x-small'"
      />
      <v-expand-x-transition>
        <v-btn
          v-show="currentRoute.path == '/'"
          to="/docs/publisher"
          icon="fas fa-fw fa-question"
          :size="smAndUp ? 'large' : 'x-small'"
        />
      </v-expand-x-transition>
    </div>
  </v-app-bar>
</template>

<script lang="ts" setup>
import { useAuth } from '@/composables/useAuth';
import { usePermissions } from '@/composables/usePermissions';
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';

const emits = defineEmits(['toggleNavigation']);

const { authUser } = useAuth();

const { can, canAny } = usePermissions();
const { currentRoute } = useRouter();
const { mobile, smAndUp } = useDisplay();
</script>

<template>
  <v-radio-group
    v-model="model"
    :label="label"
    :inline="inline"
    color="primary"
    :error="error"
    :error-messages="errorMessages"
    :hint="hint"
    :persistent-hint="!!hint"
    :disabled="disabled"
  >
    <v-radio
      v-for="(item, index) in items"
      :key="index"
      :value="item.value"
    >
      <template #label>
        <span class="ms-1 me-3 text-subtitle-2">
          {{ item.text.toString() }}
        </span>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

interface RadioItem {
  text: string;
  value: string | boolean | number;
}

withDefaults(defineProps<{
  label?: string;
  hint?: string;
  items?: Array<RadioItem>;
  error?: boolean;
  disabled?: boolean;
  errorMessages?: string[];
  inline?: boolean;
}>(), {
  inline: () => false,
  items: () => [{
    text: useI18n().t('states.enabled'),
    value: 1,
  }, {
    text: useI18n().t('states.disabled'),
    value: 0,
  }],
});

const model = defineModel<any>();
</script>

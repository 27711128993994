<template>
  <advanced-marker
    :options="{
      position: position,
      gmpDraggable: !readOnly,
    }"
    :pin-options="{
      glyph: markerImageToUse,
      background: color,
      glyphColor: 'white',
      borderColor: 'black',
      scale: scale,
    }"
    @dragend="updateLocation"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { AdvancedMarker } from 'vue3-google-map';

const props = withDefaults(defineProps<{
  position: google.maps.LatLngLiteral;
  readOnly?: boolean;
  title?: string;
  color?: string;
  icon?: string;
  scale?: number;
}>(), {
  readOnly: true,
  title: 'undefined',
  icon: 'warehouse',
  color: '#e9a013',
  scale: 1.25,
});

const emit = defineEmits(['update:position']);

const markerImageToUse = computed(() => {
  const m = document.createElement('div');
  m.className = 'dot d-flex justify-center align-center';
  m.innerHTML = `<i class="fa fa-${props.icon} fa-lg" />`;
  m.style.color = props.color;
  m.style.fontSize = `${props.scale - 0.2}em`;
  return m;
});

function updateLocation(e) {
  emit('update:position', e);
}
</script>

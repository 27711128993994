<template>
  <info-window
    v-if="infoWindowOpen"
    :options="infoOptions"
    @closeclick="infoWindowOpen = false"
  >
    <v-icon
      :icon="`fas fa-${icon}`"
      :color="iconColor"
    />

    <div class="text-body-2">
      <span class="font-weight-bold">{{ t('location.title') }}: </span>
      {{ title }}
    </div>

    <div class="text-body-2">
      <span class="font-weight-bold">{{ t('location.type') }}: </span>
      {{ typeName }}
    </div>
    <div
      v-if="description"
      class="text-body-2 mb-1"
    >
      <span class="font-weight-bold">{{ t('global.description') }}: </span>
      {{ description }}
    </div>
    <v-btn
      :href="url"
      :color="iconColor"
      :text="t('actions.show_details')"
    />
  </info-window>
</template>

<script setup lang="ts">
import { useMapStore } from '@/stores/maps';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { InfoWindow } from 'vue3-google-map';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  selectedMarker: any;
  location?: App.Models.Location;
}>();

const { t } = useI18n();

const mapStore = useMapStore();
const {
  infoWindowOpen,
  infoOptions,
} = storeToRefs(mapStore);

const iconColor = computed(() => props.selectedMarker?.type?.color ?? 'store');
const icon = computed(() => props.selectedMarker?.type?.icon ?? 'warehouse');
const title = computed(() => props.selectedMarker?.name ?? props.location?.name ?? null);
const typeName = computed(() => props.selectedMarker?.type?.name ?? t('store.title'));
const description = computed(() => props.selectedMarker?.marker?.description ?? props.selectedMarker?.details ?? props.selectedMarker?.description);
const url = computed(() => props.selectedMarker?.marker?.location_id ? `/locations/${props.selectedMarker.marker.location_id}` : `/stores/${props.selectedMarker.id}`);
</script>

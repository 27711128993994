<template>
  <div>
    <div class="text-h5">
      {{ t('current_needs.statistics_title') }}
    </div>
    <div class="text-body-1 my-2">
      {{ t('current_needs.statistics_description') }}
    </div>
    <div class="d-flex flex-row align-center">
      <v-progress-linear
        class="my-1 me-2"
        buffer-opacity="1"
        :model-value="100"
        style="max-width: 15px;"
        bg-color="font"
        buffer-color="warning"
        color="success"
        :height="barHeight"
        max="100"
        min="0"
        :buffer-value="100"
        rounded
      />
      <div class="text-body-1">
        {{ t('current_needs.assigned_publishers') }}
      </div>
    </div>
    <div class="d-flex flex-row align-center">
      <v-progress-linear
        class="my-1 me-2"
        buffer-opacity="1"
        :model-value="0"
        style="max-width: 15px;"
        bg-color="font"
        buffer-color="warning"
        color="success"
        :height="barHeight"
        max="100"
        min="0"
        :buffer-value="100"
        rounded
      />
      <div class="text-body-1">
        {{ t('current_needs.available_publishers') }}
      </div>
    </div>
    <div class="d-flex flex-row align-center">
      <v-progress-linear
        class="my-1 me-2"
        buffer-opacity="1"
        :model-value="0"
        style="max-width: 15px;"
        bg-color="font"
        buffer-color="warning"
        color="success"
        :height="barHeight"
        max="100"
        min="0"
        :buffer-value="0"
        rounded
      />
      <div class="text-body-1">
        {{ t('current_needs.no_publishers_available') }}
      </div>
    </div>
    <v-table>
      <thead>
        <tr>
          <th class="width-60">
            {{ t('date.title') }}
          </th>
          <th class="width-60">
            {{ t('shift.time_band') }}
          </th>
          <th
            v-for="(location, index) in bandLocations"
            :key="index"
            class="text-break width-60"
          >
            <span v-if="location == 'total'">{{ t('current_needs.total_publishers') }}</span>
            <span v-else>
              {{ location }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(timeband, index) in bandData"
          :key="timeband"
        >
          <td class="width-60">
            {{ t(`dow.${// @ts-ignore
              index.split('|')[0]}`) }}
          </td>
          <td class="width-60">
            {{ // @ts-ignore
              index.split('|')[1] ?? '' }}
          </td>
          <td
            v-for="location in bandLocations"
            :key="location"
            class="width-60"
          >
            <template v-if="findBandShift(location, index)">
              <div class="d-flex flex-row">
                <v-icon
                  icon="fas fas fa-user-tie"
                  size="x-small"
                />
                <v-progress-linear
                  class="my-1 ms-2"
                  buffer-opacity="1"
                  :model-value="parseFloat(findBandShift(location, index)?.assigned_captain_percentage ?? 0)"
                  bg-color="font"
                  buffer-color="warning"
                  color="success"
                  :height="barHeight"
                  max="100"
                  min="0"
                  :buffer-value="parseFloat(findBandShift(location, index)?.assigned_captain_percentage ?? 0) + parseFloat(findBandShift(location, index)?.available_captain_percentage ?? 0)"
                  rounded
                />
              </div>
              <div class="d-flex flex-row">
                <v-icon
                  icon="fas fas fa-users"
                  size="x-small"
                />
                <v-progress-linear
                  class="mb-1 ms-2"
                  buffer-opacity="1"
                  :model-value="parseFloat(findBandShift(location, index)?.assigned_percentage ?? 0)"
                  bg-color="font"
                  buffer-color="warning"
                  color="success"
                  :height="barHeight"
                  max="100"
                  min="0"
                  :buffer-value="parseFloat(findBandShift(location, index)?.assigned_percentage ?? 0) + parseFloat(findBandShift(location, index)?.available_percentage ?? 0)"
                  rounded
                />
              </div>
            </template>
          </td>
        </tr>
      </tbody>
    </v-table>

    <v-btn
      v-if="can('submit-availability')"
      color="primary"
      size="large"
      class="mt-4 float-right"
      to="/mycalendar"
      :text="t('smpw.submit_availability')"
    />
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from '@/composables';
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { can } = usePermissions();
const { t } = useI18n();
const barHeight = 14;

const bandLocations = ref([]);
const bandData = ref([]);

function findBandShift(location: string, timeband) {
  try {
    return bandData.value[timeband][location];
  }
  catch {
    return null;
  }
}
async function loadData() {
  try {
    const bandAndDayResponse = await axios.get('/api/shifts/currentNeeds/statistics');
    bandData.value = bandAndDayResponse.data.data;
    bandLocations.value = bandAndDayResponse.data.locations;
  }
  catch (error) {
    console.error(error);
  }
}

loadData();
</script>

<style scoped>
.width-60 {
  min-width: 120px !important;
}
</style>

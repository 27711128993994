<template>
  <div>
    <loading-spinner
      v-if="(loadingData > 0) || !user || !shifts"
    />
    <div
      v-else
      class="mb-2"
    >
      <div
        v-if="(authUser.id === user.id)"
        class="d-flex"
        :class="xs ? 'flex-column' : 'flex-row'"
      >
        <v-btn
          v-if="xs && can('submit-availability')"
          size="large"
          color="secondary"
          prepend-icon="fas fa-plus"
          class="mb-2"
          :class="xs ? 'float-left' : 'float-right'"
          :text="t('smpw.submit_availability')"
          to="/mycalendar"
        />
      </div>
      <div>
        <v-icon
          color="grey-darken-1"
          icon="fas fa-filter"
          class="me-2 ms-2"
          size="x-small"
        />
        <v-btn
          class="me-2 mb-2"
          :class="`${(showAssignedBtnSelected === false) ? 'filter_button_inactive' : ''}`"
          :text="t('states.assigned')"
          color="success"
          @click="filterAssigned"
        />

        <v-btn
          class="me-2 mb-2"
          :class="`${(showAvailableBtnSelected === false) ? 'filter_button_inactive' : ''}`"
          :text="t('states.available')"
          color="warning"
          @click="filterAvailable"
        />

        <v-btn
          color="error"
          class="me-2 mb-2"
          :class="`${(showCanceledBtnSelected === false) ? 'filter_button_inactive' : ''}`"
          :text="t('states.cancelled')"
          @click="filterCancelled"
        />

        <v-btn
          color="grey"
          class="me-2 mb-2"
          :class="`${(showExpiredBtnSelected === false) ? 'filter_button_inactive' : ''}`"
          :text="t('states.expired')"
          @click="filterExpired"
        />

        <v-icon
          v-if="!xs"
          color="grey-darken-1"
          icon="fas fa-minus"
          class="me-2"
        />
        <v-btn
          class="me-2 mb-2"
          :class="`${(showAllBtnSelected === false) ? 'filter_button_inactive' : ''}`"
          :text="xs ? t('global.all') : t('actions.show_all')"
          color="primary"
          @click="filterAll"
        />
      </div>
    </div>

    <v-alert
      v-if="shifts.length === 0"
      type="info"
      :text="t('shift.none_scheduled')"
    />

    <div v-if="assignedShifts.length && (showAllBtnSelected || showAssignedBtnSelected)">
      <div class="text-h5 mb-2">
        {{ t('shift.assigned_shifts') }}
      </div>
      <v-divider />
      <v-row class="mt-1">
        <v-col
          v-for="(shift, index) in assignedShifts"
          :key="index"
          cols="12"
          md="4"
          sm="6"
        >
          <shift-card
            :shift="shift"
            :date="shift.date"
            :slots="shift.timeslots"
            :locations="shift.locations"
            :user="user"
            :expiration-reason="getAvailabilityExpiration(shift)"
            :availability-label="getAvailabilityLabel(shift)"
            @reload="updateShiftData"
          />
        </v-col>
      </v-row>
      <v-divider
        class="mt-7"
      />
    </div>

    <div v-if="(availableShifts.length || exceptionShiftsDay.length || exceptionShiftsWeek.length) && (showAllBtnSelected || showAvailableBtnSelected)">
      <v-divider class="mt-7" />

      <div class="text-h5 my-2">
        {{ t('global.availability') }}
      </div>
      <v-divider />
      <v-row class="mt-1">
        <v-col
          v-for="(shift, index) in availableShifts"
          :key="index"
          cols="12"
          md="4"
          sm="6"
        >
          <shift-card
            :shift="shift"
            :slots="shift.timeslots"
            :locations="shift.locations"
            :user="user"
            :expiration-reason="getAvailabilityExpiration(shift)"
            :availability-label="getAvailabilityLabel(shift)"
            @reload="updateShiftData"
          />
        </v-col>
        <v-col
          v-for="(shift, index) in exceptionShiftsDay"
          :key="index"
          cols="12"
          md="4"
          sm="6"
        >
          <shift-card
            :shift="shift"
            :date="shift.date"
            :slots="shift.timeslots"
            :locations="shift.locations"
            :user="user"
            :expiration-reason="getAvailabilityExpiration(shift)"
            :availability-label="getAvailabilityLabel(shift)"
            @reload="updateShiftData"
          />
        </v-col>
        <v-col
          v-for="(shift, index) in exceptionShiftsWeek"
          :key="index"
          cols="12"
          md="4"
          sm="6"
        >
          <shift-card
            :shift="shift"
            :date="shift.date"
            :slots="shift.timeslots"
            :locations="shift.locations"
            :user="user"
            :expiration-reason="getAvailabilityExpiration(shift)"
            :availability-label="getAvailabilityLabel(shift)"
            @reload="updateShiftData"
          />
        </v-col>
      </v-row>
      <v-divider
        class="mt-7"
      />
    </div>

    <div v-if="expiredShifts.length && (showAllBtnSelected || showExpiredBtnSelected)">
      <div class="text-h5 my-2">
        {{ t('shift.expired_shifts') }}
      </div>
      <v-divider />
      <v-row class="mt-1">
        <v-col
          v-for="(shift, index) in expiredShifts"
          :key="index"
          cols="8"
          md="4"
          sm="6"
        >
          <shift-card
            :shift="shift"
            :date="shift.date"
            :slots="shift.timeslots"
            :locations="shift.locations"
            :user="user"
            :expiration-reason="getAvailabilityExpiration(shift)"
            :availability-label="t('states.expired')"
            @reload="updateShiftData"
          />
        </v-col>
      </v-row>
      <v-divider />
    </div>

    <div v-if="cancelledShifts.length && (showAllBtnSelected || showCanceledBtnSelected)">
      <div class="text-h5 my-2">
        {{ t('shift.cancelled_shifts') }}
      </div>
      <v-divider />
      <v-row class="mt-1">
        <v-col
          v-for="(shift, index) in cancelledShifts"
          :key="index"
          cols="8"
          md="4"
          sm="6"
        >
          <shift-card
            :shift="shift"
            :user="user"
            @reload="updateShiftData"
          />
        </v-col>
      </v-row>
      <v-divider />
    </div>
    <v-expansion-panels class="mt-2">
      <v-expansion-panel>
        <v-expansion-panel-title class="bg-light-grey">
          <div class="text-h6">
            {{ t('shift.list') }}
          </div>
          <template #actions="{ expanded }">
            <v-btn
              color="primary"
              class="ml-1"
              :icon="expanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
            />
          </template>
        </v-expansion-panel-title>

        <v-expansion-panel-text
          class="expansion-padding"
        >
          <assigned-shift-list :user-id="user.id" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup lang="ts">
import { useAuth, useDate, usePermissions } from '@/composables';
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import ShiftCard from './ShiftCard.vue';
import AssignedShiftList from './users/AssignedShiftList.vue';

const props = defineProps<{
  user: App.Models.User;
}>();

const { t } = useI18n();

const { authUser } = useAuth();
const { xs } = useDisplay();
const { toUniversalDate, planningDates } = useDate();
const shifts = ref<App.ApiResources.PublisherWithShiftDetailsResource[]>([]);
const loadingData = ref(0);
const showAllBtnSelected = ref(true);
const showAssignedBtnSelected = ref(false);
const showAvailableBtnSelected = ref(false);
const showCanceledBtnSelected = ref(false);
const showExpiredBtnSelected = ref(false);

const { can } = usePermissions();
const assignedShifts = computed(() => shifts.value?.filter((s) => (s.status === 'assigned')));
const availableShifts = ref<App.ApiResources.PublisherWithShiftDetailsResource[]>([]);

function calculateAvailableShifts(data, status = 'available'): App.ApiResources.PublisherWithShiftDetailsResource[] {
  const tempData: any = [];
  let cancelledShifts;
  let assignedShifts;
  if (status === 'available') {
    cancelledShifts = calculateAvailableShifts(data, 'cancelled');
    assignedShifts = calculateAvailableShifts(data, 'assigned');
  }
  data?.filter((s) => (s.status === status))
    .forEach((shift) => {
      const key = toUniversalDate(shift.date);
      if (!tempData[key]) {
        tempData[key] = shift;
        tempData[key].locations = [];
        tempData[key].timeslots = [];
      }
      if (cancelledShifts?.find((c) => c.shift_id === shift.shift_id) || assignedShifts?.find((c) => c.shift_id === shift.shift_id)) {
        return;
      }
      tempData[key].locations.push(shift?.location?.name);
      tempData[key].timeslots.push(
        {
          begins: shift.begins,
          ends: shift.ends,
          band: shift.band,
        },
      );
      // Remove duplicate timebands.
      tempData[key].timeslots = tempData[key].timeslots.filter((value, index, self) => index === self.findIndex((s) => (
        s.begins === value.begins && s.ends === value.ends && s.band.id === value.band.id
      )));
      // Remove duplicate locations.
      tempData[key].locations = tempData[key].locations.filter((value, index, self) => index === self.findIndex((s) => (
        s === value
      )));
    });
  return Object.values(tempData);
}
const cancelledShifts = computed(() => shifts.value?.filter((s) => (s.status === 'cancelled')));
const exceptionShiftsDay = ref<any>([]);
const exceptionShiftsWeek = ref<any>([]);
const expiredShifts = ref<any>([]);

async function updateShiftData() {
  const start = toUniversalDate(planningDates().start);
  const end = toUniversalDate(planningDates().end);
  loadingData.value += 1;
  const response = await axios.get(`/api/users/${props.user.id}/shifts?start_date=${start}&end_date=${end}`);
  shifts.value = response.data.data;
  loadingData.value -= 1;
  availableShifts.value = calculateAvailableShifts(shifts.value, 'available');
  exceptionShiftsDay.value = calculateAvailableShifts(shifts.value, 'limit_reached_day');
  exceptionShiftsWeek.value = calculateAvailableShifts(shifts.value, 'limit_reached_week');
  expiredShifts.value = calculateAvailableShifts(shifts.value, 'expired');
}

function getAvailabilityLabel(shift) {
  if (shift.status === 'limit_reached_day' || shift.status === 'limit_reached_week') {
    return t('shift.limit_reached');
  }
  return t('states.available');
}

function getAvailabilityExpiration(shift: App.ApiResources.PublisherShiftResource) {
  return shift.status === 'expired' ? t('shift.opt_out_last_minute') : '';
}

function filterAll() {
  showAllBtnSelected.value = true;
  showAssignedBtnSelected.value = false;
  showAvailableBtnSelected.value = false;
  showCanceledBtnSelected.value = false;
  showExpiredBtnSelected.value = false;
}
function filterAssigned() {
  showAllBtnSelected.value = false;
  showAssignedBtnSelected.value = true;
  showAvailableBtnSelected.value = false;
  showCanceledBtnSelected.value = false;
  showExpiredBtnSelected.value = false;
}
function filterAvailable() {
  showAllBtnSelected.value = false;
  showAssignedBtnSelected.value = false;
  showAvailableBtnSelected.value = true;
  showCanceledBtnSelected.value = false;
  showExpiredBtnSelected.value = false;
}
function filterCancelled() {
  showAllBtnSelected.value = false;
  showAssignedBtnSelected.value = false;
  showAvailableBtnSelected.value = false;
  showCanceledBtnSelected.value = true;
  showExpiredBtnSelected.value = false;
}

function filterExpired() {
  showAllBtnSelected.value = false;
  showAssignedBtnSelected.value = false;
  showAvailableBtnSelected.value = false;
  showCanceledBtnSelected.value = false;
  showExpiredBtnSelected.value = true;
}

onMounted(() => {
  updateShiftData();
});
</script>

<style scoped>
.filter_button_inactive {
    opacity: 0.5;
}
</style>

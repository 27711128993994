<template>
  <v-tab
    v-bind="props"
    :value="value"
    variant="flat"
    class="me-1 tab-unselected rounded-t-lg border-t-sm border-s-sm border-e-sm"
    selected-class="tab-selected"
  >
    <slot>{{ text }}</slot>
  </v-tab>
</template>

<script setup lang="ts">
const props = defineProps<{
  text?: string;
  value: string | number;
  active: boolean;
}>();
</script>

<template>
  <google-map
    v-if="loaded"
    ref="mapRef"
    :api-promise="apiPromise"
    :center="center"
    :street-view-control="streetViewControl"
    :rotate-control="rotateControl"
    :zoom-control="zoomControl"
    :disable-default-ui="disabledDefaultUi"
    :scale-control="scaleControl"
    :fullscreen-control="fullscreenControl"
    :map-type-control="mapTypeControl"
    :zoom="realZoom"
    map-id="DEMO_MAP_ID"
    map-type-id="roadmap"
    :style="style"
    @tilesloaded="emit('loaded')"
    @center_changed="onCenterChanged"
  >
    <slot />
  </google-map>
</template>

<script setup lang="ts">
import { Loader } from '@googlemaps/js-api-loader';
import { onMounted, ref } from 'vue';
import { GoogleMap } from 'vue3-google-map';

const props = withDefaults(defineProps<{
  // center: google.maps.LatLngLiteral;
  center: any;
  zoom?: number;
  streetViewControl?: boolean;
  rotateControl?: boolean;
  zoomControl?: boolean;
  disabledDefaultUi?: boolean;
  scaleControl?: boolean;
  fullscreenControl?: boolean;
  mapTypeControl?: boolean;
  style?: string;
}>(), {
  zoom: () => 15,
  streetViewControl: () => false,
  rotateControl: () => false,
  zoomControl: () => true,
  disabledDefaultUi: () => false,
  scaleControl: () => false,
  fullscreenControl: () => true,
  mapTypeControl: () => false,
  style: () => 'width: 100%; height: 400px',
});
const emit = defineEmits(['center:changed', 'loaded']);
// https://developers.google.com/maps/documentation/javascript/libraries
const loader = new Loader({
  apiKey: window.MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places', 'routes', 'marker'],
});
const mapRef = ref();
const realZoom = ref(props.zoom);

function onCenterChanged() {
  emit('center:changed', {
    lat: mapRef.value.map.getCenter().lat(),
    lng: mapRef.value.map.getCenter().lng(),
  });
}

const loaded = ref(false);
const apiPromise = loader.load();
onMounted(() => {
  loaded.value = true;
});
defineExpose({
  mapRef,
});
</script>

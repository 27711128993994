<template>
  <breadcrumb-header
    v-if="showBreadcrumbs"
    :items="breadcrumbs"
  />
  <v-expansion-panels
    v-if="location"
    v-model="panels"
    multiple
  >
    <v-expansion-panel
      color="primary"
      value="details"
    >
      <template #title>
        <div class="text-h6 text-white">
          {{ t('location.info') }}
        </div>
      </template>
      <template #text>
        <div
          class="overflow-x-auto rich-text-editor text-font"
          v-html="location.details"
        />
        <location-marker-editor
          v-if="showMap"
          v-model="location"
          class="mt-2"
          :store="location?.store"
          read-only
        />
      </template>
    </v-expansion-panel>
    <v-expansion-panel
      v-if="location?.images?.length"
      color="primary"
      value="gallery"
    >
      <template #title>
        <div class="text-h6 text-white">
          {{ t('images.gallery') }}
        </div>
      </template>

      <template #text>
        <image-gallery
          v-model="location.images"
          name="location_gallery"
          enable-slide-show
          read-only
        />
      </template>
    </v-expansion-panel>
    <v-expansion-panel
      v-if="location?.store_id"
      color="primary"
    >
      <template #title>
        <div class="text-h6 text-white">
          {{ t('store.info') }}
        </div>
      </template>
      <template #text>
        <show-store
          :id="location.store_id"
          :with-breadcrumbs="false"
          :show-map="false"
        />
      </template>
    </v-expansion-panel>
    <v-expansion-panel
      v-if="shiftId"
      color="primary"
      value="contacts"
    >
      <template #title>
        <div class="text-h6 text-white">
          {{ t('store.contact', 2) }}
        </div>
      </template>
      <template #text>
        <v-alert
          v-if="!storeContacts || (storeContacts.length === 0)"
          type="info"
          :text="t('store.no_contacts_available')"
          class="mt-2"
        />
        <v-container v-else>
          <v-row>
            <v-col
              v-for="(captainLocation, index) in storeContacts"
              :key="index"
              cols="12"
              sm="6"
              md="4"
            >
              <member-card
                class="mt-3"
                :user="captainLocation.captain"
                :title="captainLocation.location.name"
                :show-email="false"
                fill-height
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup lang="ts">
import { useSettings } from '@/composables';

import { useErrorStore } from '@/stores/errors.ts';
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ImageGallery from '../images/ImageGallery.vue';
import ShowStore from '../stores/ShowStore.vue';
import MemberCard from '../teams/MemberCard.vue';
import LocationMarkerEditor from './LocationMarkerEditor.vue';

const props = withDefaults(defineProps<{
  locationId: number | string;
  shiftId?: number;
  showBreadcrumbs?: boolean;
  showMap?: boolean;
}>(), {
  showBreadcrumbs: () => true,
  showMap: () => true,
});
const { getSetting } = useSettings();
const panels = ref([]);
if (!getSetting('enable_cost_savings')) {
  panels.value.push('details');
}
const { t } = useI18n();

const { handleError } = useErrorStore();

const location = ref<App.Models.Location>();

const storeContacts = ref<any>([]);
async function loadData() {
  try {
    const locationResponse = await axios.get(`/api/locations/${props.locationId}`, {
      params: {
        inc: ['images', 'markers', 'markers.images', 'heroImage', 'store', 'store.images'],
      },
    });
    location.value = locationResponse.data.data;
    if (props.shiftId) {
      const shiftResponse = await axios.get(`/api/users/shifts/${props.shiftId}/stores`);
      storeContacts.value = shiftResponse.data.data;
    }
  }
  catch (error) {
    handleError(error, t('errors.cannot_load'));
  }
}

onMounted(() => {
  loadData();
});

const breadcrumbs = computed(() => [
  {
    title: t('location.title'),
    to: '/locations',
  },
  {
    title: location.value?.name ?? t('states.loading'),
  },
]);
</script>

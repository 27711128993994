<template>
  <v-card
    density="comfortable"
    class="fill-height"
  >
    <v-card-title
      class="bg-primary"
    >
      {{ dayOverseerTitle }}
    </v-card-title>
    <v-card-text class="mt-3">
      <user-avatar :user="dayOverseer.user" />
      <h5 class="text-h6 text-primary-darken-1">
        {{ dayOverseer.user.name }}
      </h5>
      <p
        v-if="locationNames"
        class="text-subtitle-1 text-primary"
      >
        <span>{{ t('location.title', 2) }}:</span><br>
        <span>{{ locationNames }}</span>
      </p>
      <p
        v-if="dayOverseer.notes"
        class="text-subtitle-1 text-primary"
      >
        <small>
          {{ dayOverseer.notes }}
        </small>
      </p>
      <phone-number
        :number="dayOverseer.user.mobile_phone"
        class="mb-2"
      />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import UserAvatar from '../images/UserAvatar.vue';

const props = defineProps<{
  dayOverseer: App.Models.DayOverseer;
  titleType?: string;
}>();
const { t } = useI18n();

const locationNames = computed(() => {
  return props.dayOverseer.locations?.map((location) => location.name).join(', ');
});

const dayOverseerTitle = computed(() => {
  if (props.titleType === 'dow') {
    return t(`dow.${props.dayOverseer.day_of_week}`);
  }
  if (props.titleType === 'today') {
    return t(`dow.today`);
  }
  return t('dayoverseer.contact', { type_of_overseer: props.dayOverseer.order_name });
});
</script>

<template>
  <v-card class="fill-height text-center d-flex flex-column">
    <div>
      <v-toolbar
        density="compact"
        color="primary"
      >
        <v-spacer />
        <v-card-text class="text-subtitle-1 text-center text-white">
          {{ toLongDateWithDay(shiftDate) }}
        </v-card-text>
        <v-spacer />
      </v-toolbar>

      <div v-if="isAssigned">
        <v-toolbar
          density="compact"
          color="green-lighten-4"
        >
          <v-spacer />
          <v-icon
            icon="fas fa-check"
            color="green-lighten-1"
            class="me-1"
          />
          <div class="text-primary-darken-1">
            {{ t('states.assigned') }}
          </div>
          <v-spacer />
        </v-toolbar>
        <v-divider
          color="green-darken-1"
          class="border-opacity-100"
          thickness="8"
        />
      </div>
      <div v-if="isCancelled">
        <v-toolbar
          density="compact"
          color="red-lighten-4"
        >
          <v-spacer />
          <v-icon
            class="me-1"
            icon="fas fa-times"
            color="error"
          />
          <div class="text-red-darken-1">
            {{ t('states.cancelled') }}
          </div>
          <v-spacer />
        </v-toolbar>
        <v-divider
          color="red-darken-1"
          class="border-opacity-100"
          thickness="8"
        />
      </div>
      <div v-if="isAvailable">
        <v-toolbar
          density="compact"
          class="text-center border-bottom-0"
          :color="`${(isExpired || shift?.status === 'limit_reached_day' || shift?.status === 'limit_reached_week') ? 'grey' : 'orange-lighten-4'}`"
        >
          <v-spacer />
          <v-icon
            v-if="isExpired || shift?.status === 'limit_reached_day' || shift?.status === 'limit_reached_week'"
            icon="fas fa-times"
            class="me-1"
            color="dark-grey"
          />
          <v-icon
            v-else
            icon="fas fa-hourglass-half"
            class="me-1"
            color="amber-darken-2"
          />
          <div
            :class="`${(isExpired || shift?.status === 'limit_reached_day' || shift?.status === 'limit_reached_week') ? 'text-grey-darken-4' : 'text-amber-darken-4'}`"
          >
            {{ availabilityLabel }}
          </div>
          <v-spacer />
        </v-toolbar>
        <v-divider
          :color="`${(isExpired || shift?.status === 'limit_reached_day' || shift?.status === 'limit_reached_week') ? 'grey-darken-4' : 'orange'}`"
          class="border-opacity-100"
          thickness="8"
        />
      </div>
    </div>
    <v-card-text>
      <v-list-item
        v-if="shift?.tags?.length"
        class="text-center px-3 pb-0"
      >
        <shift-tag
          v-for="(shiftTag, $index) in shift?.tags"
          :key="$index"
          :shift-tag="shiftTag"
          size="large"
          class="mb-3"
        />
      </v-list-item>
      <v-list-item
        v-if="isAssigned && (acknowledgedToday || shiftIsToday || shiftIsTomorrow || isBankHoliday || isCaptain)"
        class="text-center"
      >
        <v-chip
          v-if="acknowledgedToday"
          text-color="info"
          class="mx-1"
          color="amber-darken-1"
          :text="t('states.new')"
        />
        <v-chip
          v-if="shiftIsTomorrow"
          color="success"
          class="mx-1"
          :text="t('date.tomorrow')"
        />
        <v-chip
          v-if="shiftIsToday"
          color="error"
          class="mx-1"
          :text="t('date.today')"
        />
        <v-chip
          v-if="isBankHoliday"
          :text="t('date.bank_holiday')"
          color="#7f18c0"
          class="text-white mx-1"
        />
        <v-chip
          v-if="isCaptain"
          color="primary"
          class="mx-1"
          :text="getSetting('captain_label')"
        />
      </v-list-item>
      <!-- Time -->
      <div class="text-subtitle-1 text-primary text-center mb-1 mt-2">
        {{ t('date.time') }}
      </div>
      <div
        v-if="!isAvailable"
        class="text-grey text-center text-subtitle-2"
      >
        {{ getShiftCaption(shift) }}
      </div>
      <div v-else>
        <p
          v-for="(slot, slotIndex) in sortedSlots"
          :key="slotIndex"
          class="text-grey text-center mb-1 text-subtitle-2"
        >
          {{ getTimeSlotCaption(slot) }}
        </p>
      </div>

      <!-- Location -->
      <div class="text-primary text-center my-1 text-subtitle-1">
        {{ t('location.title') }}
      </div>
      <div v-if="!isAvailable">
        <div class="text-grey text-center mb-1 text-subtitle-2">
          {{ shift?.location?.name }}
        </div>
      </div>
      <div v-else>
        <p
          v-for="(location, index) in locations"
          :key="index"
          class="text-grey text-center mb-1"
        >
          {{ location }}
        </p>
      </div>
    </v-card-text>

    <v-card-text class="d-flex flex-column align-end flex-grow-0">
      <v-btn
        v-if="isAssigned"
        color="primary"
        block
        class="mb-2"
        :text="t('shift.details')"
        size="default"
        @click="showShiftDetails(shift)"
      />
      <cancel-shift-dialog
        v-if="isAssigned && shift"
        :user="user"
        class="mb-2"
        :shift="shift"
        @shift:cancelled="emit('reload')"
      />
      <remove-shift-dialog
        v-if="isAssigned && canRemoveShift && shift"
        :user="user"
        :shift="shift"
        class="mb-2"
        @shift:removed="emit('reload')"
      />
      <v-btn
        v-if="isAvailable"
        block
        color="primary"
        :text="t('smpw.edit_availability')"
        size="default"
        @click="editAvailability(shiftDate)"
      />
      <v-btn
        v-if="isAvailable"
        color="error"
        class="mt-2"
        block
        :text="t('smpw.delete_availability')"
        size="default"
        @click="deleteAvailability = true"
      />
      <uncancel-shift-dialog
        v-if="isCancelled && shift"
        :user="user"
        :shift="shift"
        @shift:uncancelled="emit('reload')"
      />

      <shift-details-dialog ref="sdd" />

      <delete-availability-dialog
        v-if="isAvailable"
        v-model="deleteAvailability"
        :date="shiftDate"
        :user="user"
        @availability:deleted="emit('reload')"
      />
      <availability-dialog
        v-if="isAvailable"
        ref="avd"
        :user="user"
        @availability:updated="emit('reload')"
      />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import AvailabilityDialog from '@/components/dialogs/AvailabilityDialog.vue';
import ShiftDetailsDialog from '@/components/dialogs/ShiftDetailsDialog.vue';
import {
  useDate,
  usePermissions,
  useSettings,
} from '@/composables';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import CancelShiftDialog from './dialogs/CancelShiftDialog.vue';
import DeleteAvailabilityDialog from './dialogs/DeleteAvailabilityDialog.vue';
import RemoveShiftDialog from './dialogs/RemoveShiftDialog.vue';
import UncancelShiftDialog from './dialogs/UncancelShiftDialog.vue';
import ShiftTag from './ShiftTag.vue';

const props = defineProps<{
  user: App.Models.User;
  shift?: App.ApiResources.PublisherShiftResource;
  date?: string;
  slots?: Record<string, Slot>;
  locations?: App.Models.Location[];
  expirationReason?: string;
  availabilityLabel?: string;
}>();

const emit = defineEmits(['reload']);

const { t } = useI18n();

// Only used here and probably temporary
interface Slot {
  begins: string;
  ends: string;
  band: App.Models.ShiftTimeBand;
  tags: App.ApiResources.BasicShiftTagResource[];
}

const deleteAvailability = ref(false);
const { getSetting } = useSettings();
const { can } = usePermissions();
const {
  toLongDateWithDay,
  toShortTime,
  isTomorrow,
  isToday,
} = useDate();

const sdd = ref<typeof ShiftDetailsDialog>();
const avd = ref<typeof AvailabilityDialog>();

const shiftDate = computed(() => props.shift?.date);
const shiftIsToday = computed(() => isToday(props.shift?.date));
const shiftIsTomorrow = computed(() => isTomorrow(props.shift?.date));
const canRemoveShift = computed(() => can('manage-shifts'));
const isAvailable = computed(() => props?.shift?.status === 'available' || props?.shift?.status === 'limit_reached_day' || props.shift?.status === 'limit_reached_week' || props?.shift?.status === 'expired');
const isAssigned = computed(() => props?.shift?.status === 'assigned');
const isExpired = computed(() => props?.shift?.status === 'expired');
const isCancelled = computed(() => props?.shift?.status === 'cancelled');
const isCaptain = computed(() => props?.shift?.is_captain ?? false);
const isBankHoliday = computed(() => props?.shift?.is_holiday ?? false);
const acknowledgedToday = computed(() => isToday(props.shift?.acknowledged_at));

const sortedSlots = computed(() => Object.values(props.slots)
  .sort((a, b) => new Date(a.begins).getTime() - new Date(b.begins).getTime()));

function showShiftDetails(shift) {
  sdd.value?.show(shift.shift_id);
}

function getShiftCaption(shift) {
  return `${toShortTime(shift?.begins)} - ${toShortTime(shift?.ends)} - ${shift?.band?.name}`;
}

function getTimeSlotCaption(slot) {
  return `${toShortTime(slot.begins)} - ${toShortTime(slot.ends)} - ${slot.band?.name}`;
}

function editAvailability(date) {
  avd.value?.updateAvailability(date);
}
</script>

<template>
  <v-btn
    v-tooltip:bottom="t('actions.delete')"
    color="error"
    :size="size"
    @click="deleteItemConfirm"
  >
    <v-icon
      icon="fas fa-trash"
    />
  </v-btn>
  <v-dialog
    v-model="dialog"
    width="auto"
    max-width="500px"
  >
    <global-card
      :title="t('actions.delete')"
      heading-color="error"
      closable
      @close="close"
    >
      <span class="text-body-1">
        {{ t('global.delete_confirm', { item: modelName }) }}
      </span>
      <template #actions>
        <v-btn
          color="dark-grey"
          :text="t('actions.close')"
          @click="close"
        />
        <v-btn
          color="primary"
          :text="t('global.ok')"
          @click="deleteItem"
        />
      </template>
    </global-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { useErrorStore } from '@/stores/errors.ts';
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  modelName?: string;
  deleteUrl: string;
  size?: string;
}>();
const emit = defineEmits(['model:deleted']);
const { handleError } = useErrorStore();
const { t } = useI18n();

const { showSnackMessage } = useErrorStore();

const dialog = ref(false);

function close() {
  dialog.value = false;
}

function deleteItemConfirm() {
  dialog.value = true;
}

async function deleteItem() {
  try {
    await axios.delete(props.deleteUrl);
    showSnackMessage(t('global.success_deleted', { item: props.modelName }), 'success');
    emit('model:deleted');
  }
  catch (error) {
    const errorMessage = t('errors.cannot_delete_x', { x: props.modelName });
    handleError(error, errorMessage);
  }
  finally {
    close();
  }
}
</script>

<template>
  <div>
    <div class="mb-2">
      <v-btn
        v-for="(markerType, index) in markerTypes"
        :key="index"
        class="me-1 mb-1"
        :text="`${markerType.name} (${markerType.markers?.length})`"
        :class="{ 'opacity-inactive': hiddenMarkerTypes.includes(markerType.id) }"
        :color="markerType.color"
        @click="hideOrShowMarkerType(markerType)"
      />
      <v-btn
        class="me-1 mb-1"
        :text="`${t('store.title')} (${storeMarkers?.length})`"
        :class="{ 'opacity-inactive': storesHidden }"
        color="store"
        @click="storesHidden = !storesHidden"
      />
    </div>
    <smpw-map
      ref="map"
      map-type-id="roadmap"
      :style="`height: ${mapHeight}px`"
      :center="{ lat: getSetting('city_centre_lat'), lng: getSetting('city_centre_lng') }"
      :zoom="15"
      @loaded="executeAutoZoom"
    >
      <smpw-map-marker
        v-for="(m, index) in visibleLocationMarkers"
        :key="index"
        :position="m.position"
        :color="m?.type?.color"
        :icon="m?.type?.icon"
        @click="toggleInfoWindow(m, index)"
      />
      <smpw-map-marker
        v-for="(m, index) in visibleStoreMarkers"
        :key="index"
        :position="m.position"
        :title="m.title"
        @click="toggleInfoWindow(m, index + locationMarkers.length)"
      />
      <smpw-map-info-window :selected-marker="selectedMarker" />
    </smpw-map>
  </div>
</template>

<script setup lang="ts">
import SmpwMap from '@/components/globals/SmpwMap.vue';
import SmpwMapMarker from '@/components/globals/SmpwMapMarker.vue';
import { useSettings } from '@/composables';
import { useErrorStore } from '@/stores/errors';
import { useMapStore } from '@/stores/maps';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import SmpwMapInfoWindow from '../globals/SmpwMapInfoWindow.vue';

const { t } = useI18n();
const { getSetting } = useSettings();
const { handleError } = useErrorStore();
const mapHeight = computed(() => (window.innerHeight - 170 - (window.innerHeight * 0.04)));
const mapStore = useMapStore();

const {
  infoWindowOpen,
  currentInfoWinMarker,
  infoOptions,
} = storeToRefs(mapStore);

const locationMarkers = ref<Array<any>>([]);
const storeMarkers = ref<Array<any>>([]);
const markerTypes = ref([]);
const hiddenMarkerTypes = ref([]);
const storesHidden = ref(false);
const selectedMarker = ref();
// Reference to the google map object.
const map = ref();

const visibleLocationMarkers = computed(() => locationMarkers.value.filter((marker) => !hiddenMarkerTypes.value.includes(marker?.type?.id)));
const visibleStoreMarkers = computed(() => storesHidden.value ? [] : storeMarkers.value);

async function executeAutoZoom() {
  await new Promise(r => setTimeout(r, 500));
  if (!map.value) {
    return;
  }
  const bounds = new google.maps.LatLngBounds();
  storeMarkers.value.forEach((m) => {
    bounds.extend(m.position);
  });
  locationMarkers.value.forEach((m) => {
    bounds.extend(m.position);
  });
  map.value.mapRef.map.fitBounds(bounds);
}

async function fetchLocationMarkers() {
  try {
    const response = await axios.get('/api/locations', { params: { active: true, inc: ['markers', 'markers.type'] } });
    response.data.data.forEach((location) => {
      locationMarkers.value.push(...location.markers.map((marker) => {
        marker.name = location.name;
        marker.icon = location?.type?.icon;
        marker.color = location?.type?.color;
        marker.type_name = location?.type?.name;
        marker.url = `/locations/${location.id}`;
        return marker;
      }));
    });
    executeAutoZoom();
  }
  catch (error) {
    handleError(error, t('location.could_not_load_markers'));
  }
}
async function fetchStoreMarkers() {
  try {
    const response = await axios.get('/api/stores', { params: { active: true } });
    storeMarkers.value = response.data.data.map((store) => ({
      id: store.id,
      name: store.name,
      icon: 'warehouse',
      description: store.details,
      position: {
        lat: Number.parseFloat(store.lat),
        lng: Number.parseFloat(store.lng),
      },
      type_name: t('location.literature_store'),
    }));
    executeAutoZoom();
  }
  catch (error) {
    storeMarkers.value = [];
    console.error(error);
  }
}

async function fetchMarkerTypes() {
  try {
    const response = await axios.get('/api/locations/markers', { params: { inc: ['markers'], per_page: 1000 } });
    markerTypes.value = response.data.data;
    markerTypes.value.forEach(markerType => {
      if (markerType.show_on_global_map === false) {
        hiddenMarkerTypes.value.push(markerType.id);
      }
    });
  }
  catch (error) {
    handleError(error);
  }
}

async function toggleInfoWindow(marker, idx) {
  // Check type of IDX, filter through.
  if (currentInfoWinMarker.value === idx) {
    infoWindowOpen.value = !infoWindowOpen.value;
  }
  else {
    infoOptions.value = {
      position: marker.position,
      /* @ts-ignore */
      pixelOffset: {
        width: 0,
        height: -35,
      },
    };
    selectedMarker.value = marker;
    currentInfoWinMarker.value = idx;
    infoWindowOpen.value = true;
  }
}

function hideOrShowMarkerType(markerType) {
  if (hiddenMarkerTypes.value.includes(markerType.id)) {
    hiddenMarkerTypes.value = hiddenMarkerTypes.value.filter((id) => id !== markerType.id);
  }
  else {
    hiddenMarkerTypes.value.push(markerType.id);
  }
}
onMounted(() => {
  fetchLocationMarkers();
  fetchStoreMarkers();
  fetchMarkerTypes();
});

executeAutoZoom();
</script>
